/* Header */
.iframe-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 10px 5px 10px;
  background-color: #f0f0f0; /* Header background */
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  color: white;
}

/* Header buttons */
.iframe-button {
  padding: 0px;
  margin: 0 5px;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.iframe-button img, .iframe-button svg {
  margin-left: 0px;
}

/* Right buttons container */
.right-buttons, .right-controls, .control-buttons {
  display: flex;
  align-items: center;
}

.right-buttons {
  gap: 8px;
}

/* Content area */
.iframe-content {
  flex: 1 1 0%;
  display: flex;
  min-height: 0;
  overflow: hidden;
}

/* Inner iframe */
.chatbot-iframe {
  flex: 1 1 0%;
  width: 100%;
  border: none;
  min-height: 0;
}

/* ================================================== */
/* ================================================== */

/* Active language highlight */
.active-language {
  border-bottom: 0px solid #FFFFFF;
  font-weight: bold;
  padding-bottom: 2px;
}

/* Flags container */
.flags-container {
  display: flex;
  align-items: center;
  margin-right:10px;
}

/* User Info */
.user-info, .left-buttons {
  display: inline-block;
}

.left-buttons .iframe-button {
  display: inline-block;
}

.user-name {
  padding-left: 0px;
  font-size: 1em;
  color: #fff;
  margin-right: 3px; /* Spacing between name and lock icon */
}

.home-button {
  display: flex;
  padding-left: 4px;
  padding-right: 4px;
}

/* User avatar image */
.user-avatar {
  height: 1.4em; /* Set height equal to the line height of the text */
  width: auto; /* Maintain aspect ratio */
  border-radius: 50%;
  object-fit: cover;
  margin-right: 8px; /* Spacing between avatar and name */
}

/* Placeholder avatar icon */
.user-avatar-placeholder, .lock-icon {
  font-size: 1.4em;
  color: #fff;
  margin-right: 8px;
}

.lock-icon {
  font-size: 0.7em;
}

.user-avatar, .user-avatar-placeholder, .lock-icon {
  vertical-align: middle;
}

.icon-spacing {
  margin-right: 5px;
}

/* ================================================== */
/* ================================================== */

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.modal-button, .modal-close-button {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.modal-button {
  background-color: #007bff;
}

.modal-button:hover {
  background-color: #0056b3;
}

.modal-close-button {
  background-color: #dc3545;
}

.modal-close-button:hover {
  background-color: #c82333;
}

/* Input styles */
input[type="email"], input[type="password"] {
  display: block;
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Primary and link buttons */
.primary-button {
  background-color: #28a745;
  font-weight: bold;
}

.primary-button:hover {
  background-color: #218838;
}

.link-button {
  background: none;
  border: none;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.link-button:hover {
  color: #0056b3;
}

