.nlux-AiChat-root {
    /* AHAA */
    --nlux-chtr--ftSz:14px;
    /* font-family: var(--nlux-chtr--fnFm), sans-serif;
    font-size: var(--nlux-chtr--ftSz);
    font-weight: var(--nlux-chtr--fnWg);
    font-style: normal;

    line-height: var(--nlux-chtr--lnH);
    color: var(--nlux-chtr--txtClr);

    font-variation-settings: 'slnt' 0;

    > .nlux-chatRoom-container {
        border: var(--nlux-chtr--brdrWd) solid var(--nlux-chtr--brdClr);
        border-radius: var(--nlux-chtr--brdrRd);
        background-color: var(--nlux-chtr--bgClr);

        > .nlux-composer-container {
            border-top: var(--nlux-dvr--brdrWd) solid var(--nlux-chtr-dvr--clr);
        }
    } */
}

.nlux-comp-welcomeMessage>.nlux-comp-welcomeMessage-text {
    padding:10px;    
}

.nlux-conversationStarters-container>.nlux-comp-conversationStarters>.nlux-comp-conversationStarter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align items to the top */
    align-items: center;
    width: 80px; /* Adjust width as needed */
    height: 90px; /* Adjust height as needed */
    background-color: #ff8300;
    border-radius: 10px;
    border: none;
    color: white;
    text-align: center;
    cursor: pointer;
    padding: 10px;
    transition: all 0.3s ease;
}

.nlux-conversationStarters-container>.nlux-comp-conversationStarters>.nlux-comp-conversationStarter:hover {
    background-color: #ff9900; /* Slight change on hover */
}

.nlux-comp-conversationStarter-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px; /* Add some space between icon and text */
}

.csicons {
    font-size: 1rem; /* Larger icon size, adjust as needed */
    color: white;
}

.nlux-comp-conversationStarter-prompt {
    font-size: 0.8rem; /* Adjust the text size if necessary */
    color: white;
    flex-shrink: 0; /* Prevent text from shrinking */
    line-height: 1.4em!important;
}

/* Optional: Add responsive behavior */
@media (max-width: 600px) {
    .nlux-comp-conversationStarter {
        width: 100px;
        height: 100px;
    }
    .csicons {
        font-size: 1rem;
    }
}

.nlux-comp-message {
    margin-top: 20px!important;
}

/* ================================================================== */
/* ================================================================== */

  
/* Special style if the message starts with '!' */
.message-special {
  background-color: black!important;
  color: white!important;
}

/* ================================================================== */
/* ================================================================== */

.nlux-comp-chatItem.nlux-comp-chatItem--bubblesLayout.nlux-comp-chatItem--received {
    padding-right: 0px;
    margin-right: 65px;
}
.nlux-comp-chatItem.nlux-comp-chatItem--bubblesLayout.nlux-comp-chatItem--sent {
    padding-left: 0px;
    margin-left: 65px;
}
  
/* Styles for mobile devices */
@media (orientation: portrait){
    .nlux-comp-chatItem.nlux-comp-chatItem--bubblesLayout.nlux-comp-chatItem--received {
      padding-right: 0px;
      margin-right: 0px;
    }
    .nlux-comp-chatItem.nlux-comp-chatItem--bubblesLayout.nlux-comp-chatItem--sent {
      padding-left: 0px;
      margin-left: 0px;
    }
}

.nlux-comp-avatar{
    margin-top: 10px;
}

.nlux-comp-welcomeMessage-text {
    text-align: center;
    padding: 5vw;
}

/* Default state using the first icon */
.nlux-comp-composer button {
    --nlux-send-icon: url('data:image/svg+xml,\
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" \
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">\
        <g transform="translate(-4, 0) rotate(+45, 12, 12)">\
            <path d="M22 2L11 13" />\
            <path d="M22 2L15 22 11 13 2 9 22 2Z" />\
        </g>\
        </svg>\
    ');
    color: #556b2f!important;

  }
  
  /* On hover, switch to the filled icon */
  .nlux-comp-composer button:hover {
    color: rgb(13, 74, 13);
    --nlux-send-icon: url('data:image/svg+xml,\
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" \
            stroke="none">\
        <g transform="translate(-4, 0) rotate(45, 12, 12)">\
            <path d="M22 2L11 13" />\
            <path d="M22 2L15 22 11 13 2 9 22 2Z" />\
        </g>\
        </svg>\
    ');
    color: #556b2f!important;    
  }


.nlux-comp-composer {   
    padding: 5px 5px 5px 5px ;
    margin: 0px 0px 5px 5px ;
}

.nlux-comp-composer {
    position: relative; /* Ensure the triangle is positioned relative to this container */
}
  
.nlux-comp-composer textarea {
    overflow-y: hidden; /* Show scrollbar only when content overflows */
}

.nlux-composer-container {
    border-top-color: transparent!important;
}

.nlux-comp-composer::before {
    content: '';
    position: absolute;
    left: 7px; /* Move the triangle 5px to the right */
    top: 57%; /* Vertically center it relative to the textarea */
    transform: translateY(-80%) rotate(90deg); /* Rotate the triangle 90 degrees clockwise and center it vertically */

    /* Creating the triangle with dark pastel green */
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 7px solid #556b2f; /* Dark pastel green color */
    width: 0;
    height: 0;
}
  

.nlux-AiChat-root.nlux-theme-nova[data-color-scheme='light'] {

       /** Prompt container */
    --nlux-composer-container--BackgroundColor: transparent;

    /** Prompt input colors >>>>>>>>>>>>>>THIS IS IN USE <<<<<<<<<<<<<<<<<<< */
    --nlux-PromptInput--BackgroundColor: #ffffff;
    --nlux-PromptInput--BorderColor: #a3d795; 
    --nlux-PromptInput--TextColor: #121214;

    --nlux-PromptInput-Active--BackgroundColor: #f8fff8;
    --nlux-PromptInput-Active--BorderColor: #1e6c08;
    --nlux-PromptInput-Active--TextColor: #000000;

    --nlux-PromptInput-Disabled--BackgroundColor: #e5e5e5;
    --nlux-PromptInput-Disabled--TextColor: #71717a;
    --nlux-PromptInput-Disabled--BorderColor: transprent;

    --nlux-PromptInput-Placeholder--TextColor: #71717a;
    --nlux-PromptInput-Focus-Outline--Color: transparent;

    /* prompt layot */
    --nlux-PromptInput--FontSize: 14px;
    --nlux-PromptInput--BorderWidth: 1px;
    --nlux-PromptInput--BorderRadius: 10px;
    --nlux-PromptInput-Focus-Outline--Width: 2px;
    --nlux-PromptInput-Max-Height: 80px;
    --nlux-PromptInput-Height: 60px;

    /* Human message in chat */
    --nlux-HumanMessage--BackgroundColor: #acf4b7;
    --nlux-HumanMessage--BorderColor: transparent;
    --nlux-HumanMessage--TextColor: #033104;

    /** AI message in chat */
    --nlux-AiMessage--BackgroundColor: #f1f1f1;
    --nlux-AiMessage--BorderColor: transparent;
    --nlux-AiMessage--TextColor: #000000;

    /** Composer */
    /** Composer 
        --nlux-prmBx--gap: var(--nlux-Composer--Gap, 1px); /* Gap between prompt input and submit button 
    */

    --nlux-prmBx--gap:  0px; /* Gap between prompt input and submit button */

    /** Composer input */
    --nlux-prmInp--ftFm: var(--nlux-PromptInput--FontFamily);
    --nlux-prmInp--ftSz: var(--nlux-PromptInput--FontSize);
    --nlux-prmInp--brdrWd: var(--nlux-PromptInput--BorderWidth);
    --nlux-prmInp--brdrRd: var(--nlux-PromptInput--BorderRadius);
    --nlux-prmInp-fcs-otln--wd: var(--nlux-PromptInput-Focus-Outline--Width, 6px);
    --nlux-prmInp-mxhg: var(--nlux-PromptInput-Max-Height, 80px);
    --nlux-prmInp-hg: var(--nlux-PromptInput-Height, 60px);

    /** Submit button */
    --nlux-sbmBt--wd: var(--nlux-SubmitButton--Width, 80px);
    --nlux-sbmBt--brdrWd: var(--nlux-SubmitButton--BorderWidth);
    --nlux-sbmBt--brdrRd: var(--nlux-SubmitButton--BorderRadius);
    --nlux-sbmBt-fcs-otln--wd: var(--nlux-SubmitButton-Focus-Outline--Width, 6px);

    /** Submit button colors */
    --nlux-SubmitButton--BackgroundColor: transparent;
    --nlux-SubmitButton--BorderColor: transparent;
    --nlux-SubmitButton--TextColor: #000000;

    --nlux-SubmitButton-Active--BackgroundColor: transparent;
    --nlux-SubmitButton-Active--BorderColor: transparent;
    --nlux-SubmitButton-Active--TextColor: #000000;

    --nlux-SubmitButton-Disabled--BackgroundColor: transparent;
    --nlux-SubmitButton-Disabled--BorderColor: transparent;
    --nlux-SubmitButton-Disabled--TextColor: #71717a;

    --nlux-SubmitButton-Focus-Outline--Color: transparent;

    /** Divider between composer and messages */
    --nlux-ChatRoom-Divider--MarginTop: 0px;

    /** =================================================================== */
    /** =================================================================== */

    /** Conversation starters */
    --nlux-cvStrt--pdng: var(--nlux-ConversationStarter--Padding, 0 10px);
    --nlux-cvStrt--brdrRd: var(--nlux-ConversationStarter--BorderRadius);
    --nlux-cvStrt--brdrWd: var(--nlux-ConversationStarter--BorderWidth);
    --nlux-cvStrt--gap: var(--nlux-ConversationStarter--Gap, 10px);
    --nlux-cvStrt--wd: var(--nlux-ConversationStarter--Width, 160px);
    --nlux-cvStrt--hg: var(--nlux-ConversationStarter--Height, 101px);

    /** Exceptions box */
    --nlux-Exceptions--BackgroundColor: tomato;
    --nlux-Exceptions--BorderColor: darkred;
    --nlux-Exceptions--TextColor: white;

    /** Welcome message */
    --nlux-WelcomeMessage-Avatar--BorderColor: transparent;
    --nlux-WelcomeMessage-Avatar--BackgroundColor: transparent;

    /** Conversation loader */
    --nlux-ChatRoom-Loader--Color: #71717a;

    /** Conversation content color */
    --nlux-ChatRoom--BackgroundColor: #ffffff;
    --nlux-ChatRoom--BorderColor: transparent;
    --nlux-ChatRoom--TextColor: #09090b;

    /** Participant info in chat */
    --nlux-ChatItem-ParticipantName--Color: #09090b;

    /** Code block */
    --nlux-CodeBlock--BackgroundColor: #ffefd5;
    --nlux-CodeBlock--BorderColor: transparent;
    --nlux-CodeBlock--TextColor: black;
    --nlux-CodeBlock-Hover--BackgroundColor: #efe2cf;

    /** Code block copy button */
    --nlux-CodeBlock-CopyButton--BackgroundColor: #aeaea3;
    --nlux-CodeBlock-CopyButton--BorderColor: #c5c5c3;
    --nlux-CodeBlock-CopyButton--TextColor: #ffffff;

    /** Code block copy button in clicked state */
    --nlux-CodeBlock-CopyButton-Clicked--BackgroundColor: #393930;
    --nlux-CodeBlock-CopyButton-Clicked--BorderColor: #393930;
    --nlux-CodeBlock-CopyButton-Clicked--TextColor: #ffffff;

    /** Inline code in markdown */
    --nlux-InlineCode--BackgroundColor: #fff9d8;
    --nlux-InlineCode--BorderColor: transparent;
    --nlux-InlineCode--TextColor: black;

    /** Conversation starters */
    --nlux-ConversationStarter--BackgroundColor: var(--nlux-AiMessage--BackgroundColor);
    --nlux-ConversationStarter--BorderColor: transparent;
    --nlux-ConversationStarter--TextColor: var(--nlux-AiMessage--TextColor);
}
