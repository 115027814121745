/* Reset default margins, paddings, and box-sizing */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Ensure the root elements take up full height */
html, body, #root {
  height: 100%;
}

/* Main container */
.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0; /* Allows flex items to shrink */
}

