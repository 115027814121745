/* LoginModal.css */

/* LoginModal.css */

/* Modal overlay and content styles */
.modal-overlay {
  /* ... existing styles ... */
}

.modal-content {
  background-color: #fff;
  padding: 15px; /* Base padding */
  padding-top: 45px; /* Added extra top padding */
  border-radius: 8px;
  max-width: 360px;
  width: 90%;
  position: relative;
}

/* Close button */
.close-button {
  position: absolute;
  top: 8px;
  right: 8px;
  /* You can adjust the z-index if needed */
  z-index: 10;
  /* ... other styles ... */
}

/* Heading */
.modal-content h2 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1.5em;
  text-align: center;
  color: black;
  /* Optional: If you prefer, you can add padding-top to the h2 */
  /* padding-top: 20px; */
}


/* Modal overlay and content styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 15px; /* Reduced padding */
  border-radius: 8px;
  max-width: 360px; /* Slightly smaller max width */
  width: 90%;
  position: relative;
}

/* Close button */
.close-button {
  position: absolute;
  top: 8px; /* Adjusted positioning */
  right: 8px;
  border: none;
  background: none;
  font-size: 1.2em; /* Slightly smaller font size */
  cursor: pointer;
}

/* Heading */
.modal-content h2 {
  margin-top: 0;
  margin-bottom: 15px; /* Reduced margin */
  font-size: 1.5em; /* Slightly smaller font size */
  text-align: center;
}

/* Common button styles */
.action-button {
  width: 100%;
  padding: 8px 14px; /* Reduced padding */
  margin: 5px 0; /* Reduced margin */
  background-color: #FF8C00;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 0.9em; /* Slightly smaller font size */
  cursor: pointer;
}

.action-button:hover {
  background-color: #e07c00;
}

/* Button column alignment */
.button-column {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

/* Form column alignment */
.form-column {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

/* Form group styles */
.form-group {
  margin-bottom: 10px; /* Reduced margin */
}

.form-group label {
  margin-bottom: 4px; /* Reduced margin */
  display: block;
  font-size: 0.9em; /* Reduced font size */
}

.form-group input {
  width: 100%;
  padding: 7px; /* Reduced padding */
  box-sizing: border-box;
  font-size: 0.9em; /* Reduced font size */
}

/* Error and loading messages */
.error-message {
  color: red;
  margin-bottom: 10px; /* Reduced margin */
  font-size: 0.9em; /* Slightly smaller font size */
}

.loading-message {
  color: green;
  margin-bottom: 10px; /* Reduced margin */
  font-size: 0.9em; /* Slightly smaller font size */
}
